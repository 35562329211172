<template>
  <div id="menu" :class="{ active: show }">
    <div class="hamburger" @click="show = ! show"></div>
    <div class="menu-children">
      <router-link v-if="! $root.token " to="/login">Log In</router-link>
      <router-link to="/reports">Dashboard</router-link>
      <router-link to="/account">Account</router-link>
      <router-link to="/transactions">Transactions</router-link>
      <router-link to="/kds">KDS</router-link>
      <a v-if="$root.token" @click="logout">Log Out</a>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Menu',
  props: [ 'token' ],
  data() {
    return {
      show: false,
    }
  },
  methods: {
    async logout() {
      this.$root.token = null;
      await window.localStorage.removeItem('token');
      this.$router.push({ path: '/' });
    }
  }
}
</script>

<style>

  .hamburger {
    display: none;
    position: absolute;
    right: 1.5em;
    top: 0.4em;
    line-height: 3em;
    font-size: 3em;
  }

  .hamburger:before {
    content: "";
    position: absolute;
    top: 0.25em;
    left: 0;
    width: 1em;
    height: 0.125em;
    border-top: 0.375em double #000;
    border-bottom: 0.125em solid #000;
  }

  #menu a {
    text-decoration: none;
    color: #2b2f33;
    border-bottom: 1px solid #ccc;
    display: block;
    text-align: center;
    padding: 10px;
  }

  #menu a:last-of-type { border: none; }

  #menu a:hover {
    color: #dfe7f0;
    background-color: #2b2f33;
  }

  @media screen and (max-width: 1200px) {

    .hamburger {
      display: block;
    }

    #flex_container {
      flex-direction: column;
      width: 100%;
    }

    #menu .menu-children {
      max-height: 0;
      overflow: hidden;
    }

    #menu {
      font-size: 1em;
      text-align: center;
    }

    #menu[class*="active"] .menu-children {
      max-height: max-content;
    }
  }


</style>