<template>
<Authenticated>
<div id="account">
</div>
</Authenticated>
</template>
<script>

import Authenticated from '@/components/layouts/Authenticated'
export default {
name: 'account',
components: {
Authenticated
}
}
</script>
