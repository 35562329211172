<template>
  <div class="flex-container">
    <div class="elem" id="header">
      <h3>BEAM ME UP</h3>
      <Menu />
    </div>
    <div class="elem" id="content">
      <slot/>
    </div>
  </div>
</template>

<script>

import Menu from '@/components/layouts/Menu'

export default {
  name: "Authenticated",
  components: {Menu}
}

</script>

