import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from './components/auth/Login.vue'
import Account from './components/Account.vue'
import Reports from './components/Reports.vue'
import Transactions from './components/Transactions.vue'
import KDS from './components/KDS.vue'
import App from './App'

Vue.config.productionTip = false

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    routes: [
        {path: '/', name: 'Login', component: Login},
        {path: '/account', name: 'Account', component: Account, meta: { requiresAuth: true } },
        {path: '/reports', name: 'Reports', component: Reports},
        {path: '/reports/:site', component: Reports},
        {path: '/transactions', component: Transactions},
        {path: '/kds', component: KDS},
        {path: '/login', component: Login}
    ]
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (! router.app.$root.token ) {
            next({ name: 'Login' })
        } else {
            next()
        }
    }
    else if ( to.name === 'Login' && router.app.$root.token ) {
        next({name: 'Reports'}) /* this sets the default redirect if already authenticated */
    }
    else {
        next()
    }
})


new Vue({
    router,
    data() {
        return {
            token: null
        }
    },
    el: '#app',
    render(h) {
        return h(App, {props: this.$data})
    },
    async beforeCreate() {
        this.token = await window.localStorage.getItem("token");
    }
});
