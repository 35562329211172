<template>
  <div ref="chartcontainer" id="chartcontainer">

    <div v-if="debugDateConfig">

      <h6>Local start of today {{ dateConfig.today.start.local().format("YYYY-MM-DD HH:mm:ss") }}</h6>
      <h6>UTC start of today {{ dateConfig.today.start.utc().format("YYYY-MM-DD HH:mm:ss") }}</h6>

      <h6>Local end of today {{ dateConfig.today.end.local().format("YYYY-MM-DD HH:mm:ss") }}</h6>
      <h6>UTC end of today {{ dateConfig.today.end.utc().format("YYYY-MM-DD HH:mm:ss") }}</h6>

      <h6>Local start of selected day {{ dateConfig.selectedDay.start.local().format("YYYY-MM-DD HH:mm:ss") }}</h6>
      <h6>UTC start of selected day {{ dateConfig.selectedDay.start.utc().format("YYYY-MM-DD HH:mm:ss") }}</h6>

      <h6>Local end of selected day {{ dateConfig.selectedDay.end.local().format("YYYY-MM-DD HH:mm:ss") }}</h6>
      <h6>UTC end of selected day {{ dateConfig.selectedDay.end.utc().format("YYYY-MM-DD HH:mm:ss") }}</h6>

      <h6>Local start of 7 days {{ dateConfig.week.start.local().format("YYYY-MM-DD HH:mm:ss") }}</h6>
      <h6>UTC start of 7 days {{ dateConfig.week.start.utc().format("YYYY-MM-DD HH:mm:ss") }}</h6>

      <h6>Local end of 7 days {{ dateConfig.week.end.local().format("YYYY-MM-DD HH:mm:ss") }}</h6>
      <h6>UTC end of 7 days {{ dateConfig.week.end.utc().format("YYYY-MM-DD HH:mm:ss") }}</h6>

      <h6>Local start of selected month {{ dateConfig.month.start.local().format("YYYY-MM-DD HH:mm:ss") }}</h6>
      <h6>UTC start of selected month {{ dateConfig.month.start.utc().format("YYYY-MM-DD HH:mm:ss") }}</h6>

      <h6>Local end of selected month {{ dateConfig.month.end.local().format("YYYY-MM-DD HH:mm:ss") }}</h6>
      <h6>UTC end of selected month {{ dateConfig.month.end.utc().format("YYYY-MM-DD HH:mm:ss") }}</h6>

    </div>
    <Grid :layout="layout" :col-num="cols" :row-height="autoHeight" :is-draggable="true" :is-resizable="true" :vertical-compact="true" :use-css-transforms="true" >

      <Item v-bind:key="item.i" v-for="item in layout"
         :x="cols === 1 ? 1 : item.x"
         :y="item.y"
         :w="item.w"
         :h="item.h"
         :i="item.i" @resized="resizedEvent" @moved="movedEvent" >
        <div v-if="item.report in reportData">
          <h4 v-if="reportData[item.report].config.chart && reportData[item.report].config.chart !== 'sum' && reportData[item.report].config.chart && reportData[item.report].config.chart !== 'count'">{{ reportData[item.report].config.titleFunc(reportData[item.report].date_config) }}</h4>

          <table v-if="reportData[item.report].config.chart && reportData[item.report].config.chart === 'table'">
            <tr v-bind:key="idx" v-for="(category, idx) in reportData[item.report].data ">
              <td>{{ category.category }}</td>
              <td>{{ '$' + category.value.toFixed(2) }}</td>
            </tr>
            <tr>
              <td>Total</td>
              <td>{{ '$' + reportData[item.report].data.reduce((accumulator, currentValue) => accumulator + currentValue.value, 0).toFixed(2) }}</td>
            </tr>
          </table>


          <table v-if="reportData[item.report].config.chart && reportData[item.report].config.chart === 'table_count'">
            <tr v-bind:key="idx" v-for="(category, idx) in reportData[item.report].data ">
              <td>{{ category.category }}</td>
              <td>{{ category.value }}</td>
            </tr>
            <tr>
              <td>Total</td>
              <td>{{ reportData[item.report].data.reduce((accumulator, currentValue) => accumulator + currentValue.value, 0) }}</td>
            </tr>
          </table>

          <h2 v-if="reportData[item.report].config.chart && reportData[item.report].config.chart === 'sum'">
            {{ reportData[item.report].config.titleFunc(reportData[item.report].date_config) }}
            {{ '$' + reportData[item.report].data[0].value.toFixed(2) }}
          </h2>

          <h2 v-if="reportData[item.report].config.chart && reportData[item.report].config.chart === 'count'">
            {{ reportData[item.report].config.titleFunc(reportData[item.report].date_config) }}
            {{ reportData[item.report].data[0].value + ' units' }}
          </h2>

          <ChartPie v-if="reportData[item.report].config.chart && reportData[item.report].config.chart === 'donut'" :chartdata="reportData[item.report].config.chartFunc(reportData[item.report].data)" :options="itemsoptions"/>
          <Stacked v-if="reportData[item.report].config.chart && reportData[item.report].config.chart === 'stacked'" :chartdata="reportData[item.report].config.chartFunc(reportData[item.report].data)" :options="stackedoptions"/>

        </div>

        <div class="removeItem" @click="removeItem(item.i)">X</div>
      </Item>

    </Grid>
  </div>
</template>

<script>
// import Chart from './Chart.vue';
import ChartPie from './ChartPie.vue';
import Stacked from '@/components/charts/Stacked.vue';
import dayjs from "dayjs";
import { GridLayout, GridItem } from "vue-grid-layout"

export default {
  name: 'ChartContainer',
  props: ["reportData", "layout", "dateConfig", "debugDateConfig"],
  components: {Stacked, ChartPie, Grid: GridLayout, Item: GridItem },
  data() {
    return {
      chartdata: null,
      weekdata: [],
      cols: 12,
      width: 300,
      autoHeight: 100,
      options: {
        parsing: {xAxisKey: 'value', vAxisKey: 'key'},
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: 'bottom'
        },
      },
      debug: false,
      itemsweek: {},
      itemsday: {},
      itemsoptions: {
        legend: {
          position: 'bottom'
        },
        animation: {
          duration: 0
        }
      },
      stackedoptions: {
        legend: {
          position: 'bottom'
        },
        animation: {
          duration: 0
        },
        scales: {
          xAxes: [{
            stacked: true,
          }],
          yAxes: [{
            stacked: true
          }]
        }
      }

    }
  },
  mounted() {
    console.log(this.$el.clientWidth);
    if ( this.$el.clientWidth < 900 ) {
      this.cols = 1;
      this.autoHeight = 100;
    }
  },
  methods: {
    removeItem(val) {
      const index = this.layout.map(item => item.i).indexOf(val);
      this.layout.splice(index, 1);
      this.$emit('layoutChange', this.layout);
    },
    updateDate(delta) {
      this.$emit("updateDate", delta)
    },
    updateChart() {
      let arr = [];

      if ('Previous week' in this.weekdata && 'This week' in this.weekdata) {
        arr = [
          {fill: false, data: this.weekdata['Previous week'].data, backgroundColor: '#ffe5d9', borderColor: '#22e5d9'},
          {fill: false, data: this.weekdata['This week'].data, backgroundColor: '#d8e2dc', borderColor: '#d8e2dc'}
        ];

        this.chartdata = {
          backgroundColor: '#000',
          borderColor: '#5bf8bf',
          labels: this.weekdata['This week'].labels.map(x => {
            return x + ''
          }),
          datasets: arr
        }

        this.chartdata.datasets = arr;

      }

    },
    parseData(data, key) {
      var labels = [];
      var padded_data = [];

      let start_hour = this.week.clone();
      let end_hour = this.now;

      do {
        let dateStr = start_hour.format("d HH");
        let pad = false;

        /* iterate over every return row to determine if we have data for an hour time period */
        for (var row in data.rows) {
          var day = new dayjs(data.rows[row]['key'][0])
          if (day.format("d HH") === dateStr) {
            var pad_value = data.rows[row]['value'];
            pad = true;
          }
        }

        /* handle no data returned for the period and only push the date if data*/
        padded_data.push(pad ? pad_value : 0);
        labels.push(pad ? start_hour.local().format("dddd, DD MMM") : '');

        start_hour = start_hour.add(1, 'hour');

      } while (start_hour.isBefore(end_hour));

      this.weekdata[key] = {data: padded_data, labels: labels};
      this.updateChart();
    },
    movedEvent: function(i, x, y){
      let item = this.layout.find(item => { return item.i === i });
      item.y = y;
      item.x = x;
      window.localStorage.setItem('layout', JSON.stringify(this.layout));
    },
    resizedEvent: function(i, h, w){
      let item = this.layout.find(item => { return item.i === i });
      item.h = h;
      item.w = w;
      window.localStorage.setItem('layout', JSON.stringify(this.layout));
    },
  },
}
</script>

<style lang="scss">

.inner_grid {
  display: none;
  //display: grid;
  position: absolute;
  top: 80px;
  left: 1em;
  right: 1em;
  bottom: -1em;
  //border: 1px solid red;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(8, 1fr);
  grid-gap: 10px;

  div {
    border: 1px solid #ccc;
    overflow: hidden;
  }
}

#chart1 {
  grid-column-start: 3;
  grid-column-end: span 6;
  grid-row-start: 0;
  grid-row-end: span 8;
}

#chart2 {
  grid-column-start: 9;
  grid-column-end: span 4;
  grid-row-end: span 4;
}

#chart3 {
  grid-column-start: 9;
  grid-column-end: span 4;
  grid-row-start: 5;
  grid-row-end: span 4;
  border: 1px solid gold;
}

#chart4 {
  grid-column-start: 0;
  grid-column-end: span 2;
  grid-row-start: 1;
  grid-row-end: span 2;
}

#chart5 {
  border: 1px solid gold;
  grid-column-start: 0;
  grid-column-end: span 2;
  grid-row-start: 3;
  grid-row-end: span 2;
}

#chart6 {
  border: 1px solid pink;
  grid-column-start: 0;
  grid-column-end: span 2;
  grid-row-start: 5;
  grid-row-end: span 2;
}

#chart7 {
  border: 1px solid pink;
  grid-column-start: 0;
  grid-column-end: span 2;
  grid-row-start: 7;
  grid-row-end: span 2;
}

#chart8 {
  border: 1px solid pink;
  grid-column-start: 0;
  grid-column-end: span 4;
  grid-row-start: 0;
  grid-row-end: span 5;
}



#doughnut-chart {
  display: inline;
}

h4 { text-align: left; margin-bottom: 1rem; padding-left: 5px; margin-top: 5px}

#chartcontainer {
  position: relative;
  flex: 1 1;
  padding: 1em;
  align-self: stretch;

  .date-range {
    justify-content: space-evenly;

    a {
      display: inline-flex;
      text-decoration: underline;
      margin-left: 2em;
      margin-right: 2em;
      cursor: pointer;
    }
  }

  table {
    width: 100%;
    td:last-of-type {
      text-align: right;
    }
  }
}

.vue-grid-item:not(.vue-grid-placeholder) {
  border: 1px solid #eee;
  overflow: hidden;
}
.vue-grid-item .resizing {
  opacity: 0.9;
}
.vue-grid-item .static {
  background: #cce;
}
.vue-grid-item .text {
  font-size: 24px;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 100%;
  width: 100%;
}
.vue-grid-item .no-drag {
  height: 100%;
  width: 100%;
}
.vue-grid-item .minMax {
  font-size: 12px;
}
.vue-grid-item .add {
  cursor: pointer;
}
.vue-draggable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  left: 0;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#999999'/></svg>") no-repeat;
  background-position: bottom right;
  padding: 0 8px 8px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: pointer;
}

.removeItem {
  position: absolute; right: 5px; top: 5px; font-size: 0.8em; color: #999;
}

</style>
