<template>
  <form>
    <select class="form-control" v-model="currentSite" @change="updateSite">
      <option value="-1">Select a store / site ...</option>
      <option value="bagels_all" selected="selected">All</option>
      <option value="bagels_nev">NEV</option>
      <option value="bagels_city">City</option>
      <option value="bagels_catering">Catering</option>
    </select>
  </form>
</template>
<script>
export default {
  name: 'SiteSelector',
  props: ['site'],
  data() {
    return {
      currentSite: this.site || "-1"
    }
  },
  methods: {
    updateSite() {
      console.log('Changing site/store to: '+ this.currentSite);
      this.$emit('updateSite', this.currentSite);
    }
  },
}
</script>

<style scoped lang="scss">
  select { font-size: 1em; padding: 1em; min-width: 200px; font-family: 'Lato'; margin-bottom: 1em;}

  @media screen and (max-width: 1200px) {
    select {
      min-width: calc(100vw - 2em);
    }
  }

</style>
