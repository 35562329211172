<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import {Socket} from "phoenix";

const api_socket_url = "wss://beta.api.beammeup.nz/socket";

export default {
  name: 'App',
  data: () => ({
    socket: null,
    loginError: null,
    token: null,
    breadcrumb: []
  }),
  beforeDestroy() {
    this.socket.disconnect();
  },
  methods: {
    setUpChannel() {
      this.socket = new Socket(api_socket_url, {params: {guardian_token: this.token, station: this.station}});
      console.log('Attempt to connect to socket with authentication')
      if (this.token && this.site) {
        console.log('Ready to connect socket....')
        this.socket.connect();

        /* define the channel */
        if (this.channel) {
          this.channel.leave();
        }
        this.channel = this.socket.channel("kds:bagels:" + this.site, {})
      }
    }
  }
}
</script>

<style lang="scss">

html, body {
  font-family: Lato, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0px;
  padding: 0px;
}

h2, h3 { text-align: center; margin: 0px; padding: 1em; }
input, button { font-size: 1em; padding: 1em; font-family: 'Lato';  }
.w-100 { position: relative; margin: 0px auto; width: 100%; }

#app {
  display: flex;
  flex-direction: row;
  color: #2c3e50;

  .container {
    margin: 0px auto;
  }

  .flex-container {
    display: flex;
    flex-direction: row;
    flex: 1;

    .elem {
      width: fit-content;
    }
  }

  #header {
    position: relative;
    background-color: slategrey;
    padding: 1em;
    flex: 0 1;
    min-width: 180px;
  }

  #content {
    flex: 1 1;
  }

}

@media screen and (max-width: 1200px) {

  #app {
    flex-flow: column;
  }

  .flex-container {
    flex-direction: column !important;

    .elem {
      width: 100% !important;
      height: fit-content !important;
      box-sizing: border-box !important;
    }

  }

}

</style>
