<template>
  <Unauthenticated>
    <div class="top-margin">

      <h2>BEAM ME UP</h2>
      <form v-on:submit.prevent="login">
        <input name="email" v-model="email" placeholder="Username / E-mail"/>
        <div class="error" v-if="errors && errors.email">Username / E-mail {{ errors.email.join(", ") }}</div>

        <input type="password" name="password" v-model="password" placeholder="Password"/>
        <div class="error" v-if="errors && errors.password">Password {{ errors.password.join(", ") }}</div>


        <div class="error" v-if="errors && errors.all">{{ errors.all }}</div>
        <button type="submit" value="Log In" class="w-100">Log In</button>

        <a class="password" href="/password">Forgot password?</a>

      </form>
    </div>
  </Unauthenticated>
</template>

<script>
import Unauthenticated from '@/components/layouts/Unauthenticated'
import {api} from "@/api";
import Vue from "vue";

export default {
  name: "Login",
  components: { Unauthenticated },
  data() {
    return {
      errors: {},
      email: null,
      password: null,
      token: null
    }
  },
  methods: {
    async login() {
      this.errors = {};
      try {
        let result = await api.login(this.email, this.password);
        window.localStorage.setItem("token", result.jwt_token);
        window.localStorage.setItem("company", result.company);
        this.$root.token = result.jwt_token;
        this.$router.push({ path: '/reports' });
      }
      catch(error) {
        Object.keys(error).map(code => {
          Vue.set(this.errors, code, [error[code]]);
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">

form {
  border: 1px solid #ccc;
  max-width: 400px;
  padding: 2rem;
  margin: 0px auto;
  margin-top: 1rem;
  position: relative;

  button {
    margin-bottom: 1rem;
    width: 100%;
  }

  input { box-sizing: border-box; padding: 1em; width: 100%;  }
  p.or { text-align: center; }

  .btn-outline-info:hover { background-color: inherit; }
}

form div.error {
  display: block;
  margin-bottom: 1em;
  color: #ff8197;
}

form input {
  position: relative;
  margin-bottom: 1rem;
}

form input, form button {
  display: block;
}

.password {
  display: block;
  text-align: right;
  font-size: 0.8em;
  margin-top: 10px;
}

@media screen and (max-width: 840px) {
  form {
    border: none;
    padding: 0em;
    max-width: none !important;

  }
}

</style>