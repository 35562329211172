<template>
  <Authenticated>
    <div id="reports" class="flex-container">
      <div class="command elem">
        <h3>Dashboard</h3>

        <DatePicker v-model='range' is-range />

        <SiteSelector :site="site" @updateSite="updateSite" />

        <ul>
          <h4>Available Charts</h4>
          <li @click="addReport(report.key)" v-bind:key="i" v-for="(report, i) in reports">
            {{ report.report.label }}
          </li>
        </ul>

        <div class="reset" @click="resetLayout">Reset Layout</div>
        <div class="reset" @click="debugDateConfig = ! debugDateConfig">Debug Dates</div>

      </div>
      <div v-if="site" class="chart flex-container elem">
        <ChartContainer @layoutChange="layoutChange" :debugDateConfig="debugDateConfig" :dateConfig="date_config" :layout="layout" :reportData="reportData" />
      </div>
    </div>
  </Authenticated>
</template>

<script>

import Authenticated from '@/components/layouts/Authenticated'
import ChartContainer from './ChartContainer.vue'
import SiteSelector from './SiteSelector.vue'
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc"
import dayOfYear from "dayjs/plugin/dayOfYear"

import Reports from '@/components/reports/reports'
import Vue from "vue";
import DatePicker from 'v-calendar/lib/components/date-picker.umd'

dayjs.extend(utc)
dayjs.extend(dayOfYear)

export default {
  name: 'reports',
  components: {
    Authenticated, ChartContainer, SiteSelector, DatePicker
  },
  data() {
    return {
      site: this.$route.params && this.$route.params.site ? this.$route.params.site : null,
      today: new dayjs().hour(0).minute(0).second(0).utc(),
      selectedEnd: new dayjs().hour(0).minute(0).second(0).add(1, 'days').utc(),

      range: {
        start: null,
        end: null
      },

      debugDateConfig: false,

      selectedDate: null,

      reportData: {},

      layout: []

    }
  },
  computed: {
    reports() {
      return Object.keys(Reports).map(report => { return { key: report, report: Reports[report] } });
    },
    date_config() {
      return {
        year: {
          start: this.today.clone().dayOfYear(1).utc(),
          end: this.today.clone().dayOfYear(365).add(-1, 'seconds').utc()
        },
        month: {
          start: this.today.clone().date(0).utc(),
          end: this.today.clone().date(1).add(1, 'months').add(-1, 'days').add(-1, 'seconds').utc()
        },
        week: {
          start: this.today.clone().day(0).utc(),
          end: this.today.clone().day(7).add(-1, 'seconds').utc()
        },
        selectedDay: {
          start: this.today.utc(),
          end: this.today.add(1, 'days').add(-1, 'seconds').utc()
        },
        selectedRange: {
          start: this.today.utc(),
          end: this.selectedEnd.utc()
        },
        today: {
          start: new dayjs().hour(0).minute(0).second(0).utc(),
          end: new dayjs().hour(0).minute(0).second(0).clone().add(1, 'days').add(-1, 'seconds').utc()
        }
      }
    }
  },
  async mounted() {
    let layout = await window.localStorage.getItem('layout');
    if ( layout )
      this.layout = JSON.parse(layout);
  },
  watch: {
    range() {
      if ( this.range && dayjs(this.range.start).format("YYYY-MM-DD") === dayjs(this.range.end).format("YYYY-MM-DD") ) {
        this.today = new dayjs(this.range.start).hour(0).minute(0).second(0).utc();
      }
      else {
        this.today = new dayjs(this.range.start).hour(0).minute(0).second(0).utc();
        this.selectedEnd = new dayjs(this.range.end).hour(0).minute(0).second(0).utc();
      }
      this.getReportData();

    },
    selectedDate() {
      this.today = new dayjs(this.selectedDate).hour(0).minute(0).second(0).utc();
      this.getReportData();
    },
    site: {
      immediate: true,
      handler() {
        if ( this.site )
          this.getReportData();
      }
    },
    layout: {
      immediate: true,
      handler() {
        this.getReportData();
      }
    },
  },
  methods: {
    addReport: function(report) {
      if ( ! this.layout.find(i => { return i.report === report })) {
        /* get the highest index value of the layout array, ensure uniqueness */
        let idx = this.layout.reduce(( acc, cur, a, b ) => {
          return Math.max( +(b[a].i), cur.i)
        }, 0);

        this.layout.push({
          x: (this.layout.length * 2) % (this.colNum || 12),
          y: this.layout.length + (this.colNum || 12), // puts it at the bottom
          w: 4,
          h: 4,
          i: idx + 1,
          report: report
        });
        window.localStorage.setItem('layout', JSON.stringify(this.layout));
      }
    },
    layoutChange(layout) {
      this.layout = layout;
      window.localStorage.setItem('layout', JSON.stringify(layout));
    },
    resetLayout() {
      this.layout = [];
      window.localStorage.setItem('layout', JSON.stringify(this.layout));
    },
    updateSite(site) {
      this.site = site;
      this.$router.replace({ path: '/reports/' +  this.site })
    },
    /* this is typically triggered in a change in the request parameters, e.g. date or site */
    /* TODO - only trigger fetch / updates for reports in the current layout */
    async getReportData() {

      let reports = [... new Set(this.layout.map((item) => { return item.report }))];

      reports.map(async report => {
        Vue.set(this.reportData, report, {
          config: Reports[report],
          date_config: this.date_config,
          data: await Reports.getReport(Reports[report], this.site, this.date_config)
        });
      })

    },
  },
}

</script>

<style scoped lang="scss">
#reports {
  display: flex;

  .command {
    padding: 1em;
    background-color: #2b2f33;
    color: orangered;
    flex: 0 1;
  }

  .chart { flex: 1 1; }

  ul {
    margin: 0px; padding: 0px;
    list-style: none;

    li {
      margin-bottom: 0.5em;
      cursor: pointer;
    }
  }

  .reset { text-decoration: underline; cursor: pointer; }

  .vc-container { margin-bottom: 20px; }
}
</style>
