import dayjs from "dayjs";

function parseIntoCategories(data) {
    let categories = {}
    data.rows.forEach(row => {
        let category = row.key[1];
        if ( ! ( category in categories ) ) { categories[category] = 0; }
        categories[category] += row.value;
    });
    return categories;
}

function parseIntoCategoriesByDay(data) {

    let dates = {};

    data.rows.forEach(row => {
        let day = row.key[0].slice(0,10); /* this sucks - gets rids of the hour, which removes any chance of converting to TZ correctly */
        /* possibly just handle this in the month chart function? */
        if ( ! ( day in dates ) ) { dates[day] = {}; }

        let category = row.key[1];
        if ( ! ( category in dates[day] ) ) { dates[day][category] = 0; }
        dates[day][category] += row.value;
    });

    return dates;
}

function sortByValue(data) {
    let sortedArray = [];
    Object.keys(data).forEach(category => {
        sortedArray.push({ category: category, value: data[category] });
    })
    return sortedArray;
}

const base_url = process.env.VUE_APP_API_BASE_URL;
const base_count_url = process.env.VUE_APP_API_COUNT_BASE_URL;
const base_modifier_url = process.env.VUE_APP_API_MODIFIER_BASE_URL;
const base_product_url = process.env.VUE_APP_API_PRODUCT_BASE_URL;
const colors = ["#ffbe0bff", "#219ebc", "#fb5607ff", "#f94144ff", "#f3722cff", "#f8961eff", "#f9844aff", "#f9c74fff", "#90be6dff", "#43aa8bff", "#4d908eff", "#577590ff", "#277da1ff"];

const reports = {

    'selected_day_total': {
        label: 'Selected Day Total',
        chart: 'table',
        base_url: base_url,
        startFunc: (date) => { return date.selectedDay.start.format("YYYY-MM-DDTHH") },
        endFunc: (date) => { return date.selectedDay.end.format("YYYY-MM-DDTHH") },
        groupFunc: () => { return 0 },
        sortFunc: null,
        parseFunc: (data) => { return [{category: 'Total', value: data.rows[0].value}] },
        tableFunc: (data) => { return data; },
        chartFunc: () => {},
        labelFunc: null,
        titleFunc: (date) => { return "Total " + date.selectedDay.start.local().format("YYYY-MM-DD") },
        limitFunc: null,
        legendFunc: null
    },

    'today_count': {
        label: 'Today Count',
        chart: 'count',
        base_url: base_count_url,
        startFunc: (date) => { return date.today.start.format("YYYY-MM-DDTHH") }, /* nb: the date object stores everything in UTC */
        endFunc: (date) => { return date.today.end.format("YYYY-MM-DDTHH") },
        groupFunc: () => { return 0 },
        sortFunc: null,
        parseFunc: (data) => {
            console.log(data);
            return [{category: 'Total', value: data.rows[0].value}]
        },
        tableFunc: (data) => { return data; },
        chartFunc: () => {},
        labelFunc: null,
        titleFunc: () => { return "Count" },
        limitFunc: null,
        legendFunc: null
    },

    'today_total': {
        label: 'Today Total',
        chart: 'sum',
        base_url: base_url,
        startFunc: (date) => { return date.today.start.format("YYYY-MM-DDTHH") }, /* nb: the date object stores everything in UTC */
        endFunc: (date) => { return date.today.end.format("YYYY-MM-DDTHH") },
        groupFunc: () => { return 0 },
        sortFunc: null,
        parseFunc: (data) => {
            return [{category: 'Total', value: data.rows[0].value}]
        },
        tableFunc: (data) => { return data; },
        chartFunc: () => {},
        labelFunc: null,
        titleFunc: () => { return "Today" },
        limitFunc: null,
        legendFunc: null
    },

    'today_categories_count': {
        label: 'Today Category (Count)',
        chart: 'table_count',
        base_url: base_count_url,
        startFunc: (date) => { return date.today.start.format("YYYY-MM-DDTHH") },
        endFunc: (date) => { return date.today.end.format("YYYY-MM-DDTHH") },
        groupFunc: () => { return 2 },
        sortFunc: null,
        parseFunc: (data) => { return sortByValue(parseIntoCategories(data)); },
        tableFunc: (data) => { return data; },
        chartFunc: () => {},
        labelFunc: null,
        titleFunc: () => { return "Today / Category Breakdown" },
        limitFunc: null,
        legendFunc: null
    },

    'selected_day_category_count': {
        label: 'Range Category Count',
        chart: 'table_count',
        base_url: base_count_url,
        startFunc: (date) => { return date.selectedRange.start.format("YYYY-MM-DDTHH") },
        endFunc: (date) => { return date.selectedRange.end.format("YYYY-MM-DDTHH") },
        groupFunc: () => { return 2 },
        sortFunc: null,
        parseFunc: (data) => { return sortByValue(parseIntoCategories(data)); },
        tableFunc: (data) => { return data; },
        chartFunc: () => {},
        labelFunc: null,
        titleFunc: () => { return "(Range) Category Count" },
        limitFunc: null,
        legendFunc: null
    },

    'selected_day_modifier_count': {
        label: 'Range Modifier Count',
        chart: 'table_count',
        base_url: base_modifier_url,
        startFunc: (date) => { return date.selectedRange.start.format("YYYY-MM-DDTHH") },
        endFunc: (date) => { return date.selectedRange.end.format("YYYY-MM-DDTHH") },
        groupFunc: () => { return 3 },
        sortFunc: null,
        parseFunc: (data) => { return sortByValue(parseIntoCategories(data)); },
        tableFunc: (data) => { return data; },
        chartFunc: () => {},
        labelFunc: null,
        titleFunc: (date) => { return "Modifier count. Range:  " + date.selectedRange.start.format("YYYY-MM-DD") + ' to ' + date.selectedRange.end.format("YYYY-MM-DD") },
        limitFunc: null,
        legendFunc: null
    },

    'selected_day_product_count': {
        label: 'Range Product Count',
        chart: 'table_count',
        base_url: base_product_url,
        startFunc: (date) => { return date.selectedRange.start.format("YYYY-MM-DDTHH") },
        endFunc: (date) => { return date.selectedRange.end.format("YYYY-MM-DDTHH") },
        groupFunc: () => { return 2 },
        sortFunc: null,
        parseFunc: (data) => { return sortByValue(parseIntoCategories(data)); },
        tableFunc: (data) => { return data; },
        chartFunc: () => {},
        labelFunc: null,
        titleFunc: (date) => { return "Product count. Range:  " + date.selectedRange.start.format("YYYY-MM-DD") + ' to ' + date.selectedRange.end.format("YYYY-MM-DD") },
        limitFunc: null,
        legendFunc: null
    },

    'today_categories': {
        label: 'Today Category',
        chart: 'table',
        base_url: base_url,
        startFunc: (date) => { return date.today.start.format("YYYY-MM-DDTHH") },
        endFunc: (date) => { return date.today.end.format("YYYY-MM-DDTHH") },
        groupFunc: () => { return 2 },
        sortFunc: null,
        parseFunc: (data) => { return sortByValue(parseIntoCategories(data)); },
        tableFunc: (data) => { return data; },
        chartFunc: () => {},
        labelFunc: null,
        titleFunc: () => { return "Today / Category Breakdown" },
        limitFunc: null,
        legendFunc: null
    },

    'fortnight_total': {
        label: 'Fornight Total',
        chart: 'table',
        base_url: base_url,
        startFunc: (date) => { return date.today.start.clone().add(-14, 'days').format("YYYY-MM-DDTHH") },
        endFunc: (date) => { return date.today.end.clone().format("YYYY-MM-DDTHH") },
        groupFunc: () => { return 0 },
        sortFunc: null,
        parseFunc: (data) => {
            return [{category: 'Total', value: data.rows[0].value}]
        },
        tableFunc: (data) => { return data; },
        chartFunc: () => {},
        labelFunc: null,
        titleFunc: () => { return "Fortnight Total" },
        limitFunc: null,
        legendFunc: null
    },


    'yesterday_total': {
        label: 'Yesterday Total',
        chart: 'table',
        base_url: base_url,
        startFunc: (date) => { return date.today.start.clone().add(-1, 'days').format("YYYY-MM-DDTHH") },
        endFunc: (date) => { return date.today.end.clone().add(-1, 'days').format("YYYY-MM-DDTHH") },
        groupFunc: () => { return 0 },
        sortFunc: null,
        parseFunc: (data) => {
            return [{category: 'Total', value: data.rows[0].value}]
        },
        tableFunc: (data) => { return data; },
        chartFunc: () => {},
        labelFunc: null,
        titleFunc: () => { return "Yesterday Total" },
        limitFunc: null,
        legendFunc: null
    },

    'yesterday_categories': {
        label: 'Yesterday Categories',
        chart: 'table',
        base_url: base_url,
        startFunc: (date) => { return date.today.start.clone().add(-1, 'days').format("YYYY-MM-DDTHH") },
        endFunc: (date) => { return date.today.end.clone().add(-1, 'days').format("YYYY-MM-DDTHH") },
        groupFunc: () => { return 2 },
        sortFunc: null,
        parseFunc: (data) => { return sortByValue(parseIntoCategories(data)); },
        tableFunc: (data) => { return data; },
        chartFunc: () => {},
        labelFunc: null,
        titleFunc: () => { return "Yesterday Categories" },
        limitFunc: null,
        legendFunc: null
    },



    'week_categories': {
        label: 'This Week Totals By Category (Table)',
        chart: 'table',
        base_url: base_url,
        startFunc: (date) => { return date.week.start.format("YYYY-MM-DDTHH") },
        endFunc: (date) => { return date.week.end.format("YYYY-MM-DDTHH") },
        groupFunc: () => { return 2 },
        sortFunc: null,
        parseFunc: (data) => { return sortByValue(parseIntoCategories(data)); },
        tableFunc: (data) => { return data; },
        chartFunc: () => { },
        labelFunc: null,
        titleFunc: (date) => { return "(Week) Breakdown: " + date.week.start.local().format("DD MMMM") + ' to ' + date.week.end.local().format("DD MMMM") },
        limitFunc: null,
        legendFunc: null
    },

    'week_categories_chart': {
        label: 'This Week Totals By Category (Chart)',
        chart: 'donut',
        base_url: base_url,
        startFunc: (date) => { return date.week.start.format("YYYY-MM-DDTHH") },
        endFunc: (date) => { return date.week.end.format("YYYY-MM-DDTHH") },
        groupFunc: () => { return 2 },
        sortFunc: null,
        parseFunc: (data) => {
            return sortByValue(parseIntoCategories(data));
        },
        tableFunc: (data) => { return data; },
        chartFunc: (data) => {
            return {
                backgroundColor: ['#ff0000', '#00ff00'],
                borderColor: '#5bf8bf',
                labels: Object.keys(data).map(i => {
                    return data[i].category + ': ' + '$' + data[i].value.toFixed(2)
                }),
                datasets: [{
                    data: Object.keys(data).map(category => { return data[category].value }),
                    backgroundColor: ["#ffbe0bff", "#219ebc", "#fb5607ff", "#f94144ff", "#f3722cff", "#f8961eff", "#f9844aff", "#f9c74fff", "#90be6dff", "#43aa8bff", "#4d908eff", "#577590ff", "#277da1ff"],
                }]
            }
        },
        labelFunc: null,
        titleFunc: (date) => { return "(Week) Breakdown: " + date.week.start.local().format("DD MMMM") + ' to ' + date.week.end.local().format("DD MMMM") },
        limitFunc: null,
        legendFunc: null
    },

    'selected_range_categories': {
        label: 'Selected Range Totals By Category (Table)',
        chart: 'table',
        base_url: base_url,
        startFunc: (date) => { return date.selectedRange.start.format("YYYY-MM-DDTHH") },
        endFunc: (date) => { return date.selectedRange.end.format("YYYY-MM-DDTHH") },
        groupFunc: () => { return 2 },
        sortFunc: null,
        parseFunc: (data) => { return sortByValue(parseIntoCategories(data)); },
        tableFunc: (data) => { return data; },
        chartFunc: () => { },
        labelFunc: null,
        titleFunc: (date) => { return "(Range) Breakdown: " + date.selectedRange.start.local().format("DD MMMM") + ' to ' + date.selectedRange.end.local().format("DD MMMM") },
        limitFunc: null,
        legendFunc: null
    },

    'week_categories_stacked_by_day_chart': {
        label: 'Day Total Stacked By Category',
        base_url: base_url,
        chart: 'stacked',
        startFunc: (date) => { return date.week.start.format("YYYY-MM-DDTHH") },
        endFunc: (date) => { return date.week.end.format("YYYY-MM-DDTHH") },
        groupFunc: () => { return 2 },
        sortFunc: null,
        parseFunc: (data) => {
            data = parseIntoCategoriesByDay(data);

            let categories = {}

            /* this function trys to reverse the parsed data to prepare for stacking */
            Object.keys(data).forEach((day) => {
                Object.keys(data[day]).forEach(category => {
                    if ( ! ( category in categories ) ) { categories[category] = []; }
                    categories[category].push({key: day, value: data[day][category]})
                })
            });
            return categories;
        },
        tableFunc: (data) => { return data; },
        chartFunc: (data) => {
            return {
                backgroundColor: ['#ff0000', '#00ff00'],
                borderColor: '#5bf8bf',
                labels: Object.keys(data).map((category) => {
                    return data[category].map(d => { return d.key });
                })[0],
                datasets: Object.keys(data).map((category, idx) => {
                   return {
                       label: category,
                       data: data[category].map(d => { return d.value }),
                       backgroundColor: colors[idx]
                   }
                })
            }
        },
        labelFunc: null,
        titleFunc: (date) => { return "(Week) Breakdown: " + date.week.start.local().format("DD MMMM") + ' to ' + date.week.end.local().format("DD MMMM") },
        limitFunc: null,
        legendFunc: null
    },

    'month_categories_stacked_by_day_chart': {
        label: 'Month Total Stacked By Category',
        base_url: base_url,
        chart: 'stacked',
        startFunc: (date) => {
            console.log(date.month.start.format(("YYYY-MM-DDTHH") ));
            return date.month.start.format("YYYY-MM-DDTHH");
        },
        endFunc: (date) => { return date.month.end.format("YYYY-MM-DDTHH") },
        groupFunc: () => { return 2 },
        sortFunc: null,
        parseFunc: (data) => {
            data = parseIntoCategoriesByDay(data);

            let categories = {}

            /* this function trys to reverse the parsed data to prepare for stacking */
            Object.keys(data).forEach((day) => {
                Object.keys(data[day]).forEach(category => {
                    if ( ! ( category in categories ) ) { categories[category] = []; }
                    categories[category].push({key: day, value: data[day][category]})
                })
            });
            return categories;
        },
        tableFunc: (data) => { return data; },
        chartFunc: (data) => {
            return {
                backgroundColor: ['#ff0000', '#00ff00'],
                borderColor: '#5bf8bf',
                labels: Object.keys(data).map((category) => {
                    return data[category].map(d => {
                        return dayjs(d.key).add(1, 'days').local().format("DD MMMM")
                    });
                })[0],
                datasets: Object.keys(data).map((category, idx) => {
                    return {
                        label: category,
                        data: data[category].map(d => { return d.value }),
                        backgroundColor: colors[idx]
                    }
                })
            }
        },
        labelFunc: null,
        titleFunc: (date) => { return "(Month) Breakdown: " + date.month.start.local().format("DD MMMM") + ' to ' + date.month.end.local().format("DD MMMM") },
        limitFunc: null,
        legendFunc: null
    },

    'year_categories_stacked_by_day_chart': {
        label: 'Year Total Stacked By Category',
        base_url: base_url,
        chart: 'stacked',
        startFunc: (date) => { return date.year.start.format("YYYY-MM-DDTHH") },
        endFunc: (date) => { return date.year.end.format("YYYY-MM-DDTHH") },
        groupFunc: () => { return 2 },
        sortFunc: null,
        parseFunc: (data) => {
            data = parseIntoCategoriesByDay(data);

            let categories = {}

            /* this function trys to reverse the parsed data to prepare for stacking */
            Object.keys(data).forEach((day) => {
                Object.keys(data[day]).forEach(category => {
                    if ( ! ( category in categories ) ) { categories[category] = []; }
                    categories[category].push({key: day, value: data[day][category]})
                })
            });
            return categories;
        },
        tableFunc: (data) => { return data; },
        chartFunc: (data) => {
            return {
                backgroundColor: ['#ff0000', '#00ff00'],
                borderColor: '#5bf8bf',
                labels: Object.keys(data).map((category) => {
                    return data[category].map(d => { return d.key });
                })[0],
                datasets: Object.keys(data).map((category, idx) => {
                    return {
                        label: category,
                        data: data[category].map(d => { return d.value }),
                        backgroundColor: colors[idx]
                    }
                })
            }
        },
        labelFunc: null,
        titleFunc: (date) => { return "Year by Day/Cat from   " + date.month.start.local().format("YYYY-MM-DD") + ' to ' + date.month.end.local().format("YYYY-MM-DD") },
        limitFunc: null,
        legendFunc: null
    },

    'week_totals': {
        base_url: base_url,
        startFunc: (date) => { return date.week.start.format("YYYY-MM-DDTHH") },
        endFunc: (date) => { return date.week.end.format("YYYY-MM-DDTHH") },
        groupFunc: () => { return 0 },
        sortFunc: null,
        parseFunc: (data) => { return data },
        labelFunc: null,
        titleFunc: null,
        limitFunc: null,
        legendFunc: null
    },

    async getReport(config, site, date_config) {
        let url = config.base_url + site;

        let params = '?start_key=["' + config.startFunc(date_config) + '"]&group=true&group_level=' + config.groupFunc();
            params += '&end_key=["' + config.endFunc(date_config) + '"]';

        let response = await fetch(url + params);
        let data = await response.json();

        return config.parseFunc(data);
    }



}

export default reports;

